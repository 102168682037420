<button
  type="button"
  (click)="clipboardWriteValue()"
  class="button-clipboard"
  [class.button]="button()"
  [class.rounded]="rounded()"
  [class.disabled]="disabled()"
>
  <ng-content></ng-content>
</button>
