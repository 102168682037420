import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';

import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-button-clipboard',
  imports: [],
  templateUrl: './button-clipboard.component.html',
  styleUrl: './button-clipboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonClipboardComponent {
  value = input.required<string>();
  button = input<boolean>(false);
  rounded = input<boolean>(false);
  disabled = input<boolean>(false);

  notificationService = inject(NotificationService);

  clipboardWriteValue() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.value())
        .then(() =>
          this.notificationService.success('Ссылка успешно скопирована'),
        )
        .catch(() =>
          this.notificationService.error(
            'При копировании ссылки произошла ошибка',
          ),
        );
    } else {
      console.error('Буфер обмена недоступен');
    }
  }
}
